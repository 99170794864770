import React from "react";
import Content from "../layout/Content";
import Header from "../layout/Header";

function Main() {
  return (
    <div style={styles.wrap}>
      <Header />
      <Content />
    </div>
  );
}

const styles = {
  wrap: {
    maxWidth: "500px",
    width: "100%",
  },
};

export default Main;
