import React, { useRef } from 'react';
import kakaoChannelLogo from '../../assets/img/kakao_channel.png';

function KakaoChannel() {
  const gochannel = () => {
    window.open('http://pf.kakao.com/_ZChtG', '_blank');
  };

  return (
    <div style={styles.iconEmail}>
      <img
        src={kakaoChannelLogo}
        alt="email-icon"
        width="60px"
        onClick={gochannel}
      />
    </div>
  );
}

const styles = {
  iconEmail: {
    cursor: 'pointer',
    position: 'fixed',
    bottom: 10,
    right: '1vh',
    opacity: '.8',
  },
};

export default KakaoChannel;
