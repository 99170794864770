function range(start, stop, step) {
  if (typeof stop == "undefined") {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == "undefined") {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  var result = [];
  for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
}

export const datas = [
  //catBTI - 고양이 성격 테스트
  {
    info: {
      mainTitle: "고양이 성격 유형 테스트",
      subTitle:
        "고양이에게 MBTI가 있다면? 12개의 문항으로 알아보는 CatBTI 테스트!",
      description: `Cat + MBTI = CatBTI💛\n고양이에게 MBTI가 있다면? 12개의 문항으로 알아보는 CatBTI 테스트!`,
      mainUrl: "catBTI",
      scoreType: "typeMBTI",
      mainImage:
        "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2FcatBTI_banner.png?alt=media&token=73252fc6-1141-4da9-a985-e4a4fcf56b3b",
      fullImage:
        "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2FcatBTI_full.png?alt=media&token=c9fcd519-441d-45aa-aea6-4069f1f004f4",
      resultImage:
        "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fenfj.png?alt=media&token=e670d3d3-b476-4b08-b01b-a6641a94c9cf",
      //   lang: "Kor",
      //   category: "characteristic",
    },
    questions: [
      {
        question: "집에 손님이 찾아왔다! 고양이의 반응은?",
        answers: [
          {
            type: "E",
            score: 2,
            content: "관심을 보이며 다가가거나 근처에서 쳐다본다",
          },
          {
            type: "I",
            score: 5,
            content: "경계 태세! 보이지 않는 곳에 숨어버린다",
          },
        ],
      },
      {
        question: "집사가 새로운 장난감을 가져왔다! 고양이의 반응은?",
        answers: [
          {
            type: "E",
            score: 2,
            content: "장난감을 물고 뜯고 맛보고 즐긴다",
          },
          {
            type: "I",
            score: 5,
            content: "장난감을 조심스럽게 탐색한다",
          },
        ],
      },
      {
        question: "둘 중 고양이가 더 좋아하는 것은?",
        answers: [
          {
            type: "E",
            score: 2,
            content: "이리 저리 뛰어 다니며 놀기",
          },
          {
            type: "I",
            score: 5,
            content: "최애 공간에서 혼자만의 시간 가지기",
          },
        ],
      },
      {
        question: "새로운 동물병원에 방문했다! 고양이의 반응은?",
        answers: [
          {
            type: "S",
            score: 2,
            content: `이동장 안에서 꿈쩍도 하지 않는다`,
          },
          {
            type: "N",
            score: 5,
            content: `호기심어린 눈빛으로 주변을 두리번 거린다`,
          },
        ],
      },
      {
        question: "고양이가 더 좋아하는 장난감은?",
        answers: [
          {
            type: "N",
            score: 2,
            content: "인형 달린 낚시줄",
          },
          {
            type: "S",
            score: 5,
            content: "간식이 들어있는 간식볼",
          },
        ],
      },
      {
        question: "평상시 고양이의 꼬리는?",
        answers: [
          {
            type: "S",
            score: 2,
            content: `아래로 내려가 있다`,
          },
          {
            type: "N",
            score: 5,
            content: `위로 올라가 있거나 움직임이 많다`,
          },
        ],
      },
      {
        question: `고양이가 더 자주 하는 행동은?`,
        answers: [
          {
            type: "T",
            score: 2,
            content: "집사 관찰하기",
          },
          {
            type: "F",
            score: 5,
            content: "집사 옆에 붙어있기",
          },
        ],
      },
      {
        question: "고양이를 불렀을 때 반응은?",
        answers: [
          {
            type: "T",
            score: 2,
            content: `집사를 향해 달려온다`,
          },
          {
            type: "F",
            score: 5,
            content: "아무리 불러도 꿈쩍 않는다",
          },
        ],
      },
      {
        question: `간식을 들고있을 때 고양이의 반응은?`,
        answers: [
          {
            type: "T",
            score: 2,
            content: "차분히 기다린다",
          },
          {
            type: "F",
            score: 5,
            content: "쉴 새 없이 운다",
          },
        ],
      },
      {
        question: "간식을 주면",
        answers: [
          {
            type: "J",
            score: 2,
            content: `천천히 음미하면서 먹는다`,
          },
          {
            type: "P",
            score: 5,
            content: `엄청난 속도로 먹어치운다`,
          },
        ],
      },
      {
        question: "창 밖을 구경하는 시간이 정해져있다",
        answers: [
          {
            type: "J",
            score: 2,
            content: "그렇다",
          },
          {
            type: "P",
            score: 5,
            content: "아니다",
          },
        ],
      },
      {
        question: "퇴근 후 집에 돌아왔을 때 고양이의 반응은?",
        answers: [
          {
            type: "J",
            score: 2,
            content: "문 앞에 와 반겨준다",
          },
          {
            type: "P",
            score: 5,
            content: "눈길만 주거나 신경쓰지 않는다",
          },
        ],
      },
    ],
    results: [
      {
        type: "ESTJ",
        desc: `야생의 고양이`,
        query: "ESTJ",
        score_range: range(26),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Festj.png?alt=media",
      },
      {
        type: "ESTP",
        desc: `내유외강 고양이`,
        query: "ESTP",
        score_range: range(26, 51),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Festp.png?alt=media",
      },
      {
        type: "ESFJ",
        desc: `푸근한 고양이`,
        query: "ESFJ",
        score_range: range(51, 75),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fesfj.png?alt=media",
      },
      {
        type: "ESFP",
        desc: `오지 탐험가 고양이`,
        query: "ESFP",
        score_range: range(76, 101),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fesfp.png?alt=media",
      },
      {
        type: "ENTJ",
        desc: `방구석 지배자 고양이`,
        query: "ENTJ",
        score_range: range(76, 101),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fentj.png?alt=media",
      },
      {
        type: "ENTP",
        desc: `사고뭉치 고양이`,
        query: "ENTP",
        score_range: range(26),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fentp.png?alt=media",
      },
      {
        type: "ENFJ",
        desc: `친구같은 고양이`,
        query: "ENFJ",
        score_range: range(26, 51),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fenfj.png?alt=media",
      },
      {
        type: "ENFP",
        desc: `사교적인 고양이`,
        query: "ENFP",
        score_range: range(51, 75),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fenfp.png?alt=media",
      },
      {
        type: "ISTJ",
        desc: `루틴성애자 고양이`,
        query: "ISTJ",
        score_range: range(76, 101),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fistj.png?alt=media",
      },
      {
        type: "ISTP",
        desc: `방구석 탐험가 고양이`,
        query: "ISTP",
        score_range: range(76, 101),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fistp.png?alt=media",
      },
      {
        type: "ISFJ",
        desc: `마더 테레사 고양이`,
        query: "ISFJ",
        score_range: range(76, 101),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fisfj.png?alt=media",
      },
      {
        type: "ISFP",
        desc: `매력적인 고양이`,
        query: "ISFP",
        score_range: range(26),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fisfp.png?alt=media",
      },
      {
        type: "INTJ",
        desc: `야무진 고양이`,
        query: "INTJ",
        score_range: range(26, 51),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fintj.png?alt=media",
      },
      {
        type: "INTP",
        desc: `괴짜박사 고양이`,
        query: "INTP",
        score_range: range(51, 75),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Fintp.png?alt=media",
      },
      {
        type: "INFJ",
        desc: `기묘한 고양이`,
        query: "INFJ",
        score_range: range(76, 101),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Finfj.png?alt=media",
      },
      {
        type: "INFP",
        desc: `4차원 고양이`,
        query: "INFP",
        score_range: range(76, 101),
        img_src:
          "https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/tests%2FcatBTI%2Finfp.png?alt=media",
      },
    ],
  },
];
