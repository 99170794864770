// util.js
export const shareKakao = async (title, desc, THU, type = null) => {
  if (window.Kakao) {
    // 페이지 새로고침 시에도 초기화가 되도록 수정
    if (!window.Kakao.isInitialized()) {
      await new Promise((resolve) => {
        window.Kakao.init('734e1fbe53d0ff6e986ab41b315e9149', resolve);
        window.Kakao.Link.sendCustom({
          templateId: 100538,
          templateArgs: {
            title: title,
            desc: desc,
            THU: THU,
            type: type,
          },
        });
      });
    }

    console.log('인증확인:', window.Kakao.isInitialized(), title, desc);
    window.Kakao.Link.sendCustom({
      templateId: 100538,
      templateArgs: {
        title: title,
        desc: desc,
        THU: THU,
        type: type,
      },
    });
    // 이 부분에서 kakao.Link이 undefined 에러가 나지 않도록 추가 확인
  }
};
