import React from "react";
import { shareKakao } from "../../utils/util";

function KakaoButton({ data }) {
  console.log("kakao result?", data);
  return (
    <div
      style={{
        width: "100%",
        height: "50px",
        cursor: "pointer",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center", // 세로 가운데 정렬을 위해 추가
        justifyContent: "center",
        backgroundColor: "#F2DD03",
      }}
      onClick={() =>
        shareKakao(
          data.catBtiData.info.mainTitle,
          data.resultObject.desc,
          data.resultObject.img_src,
          `result/${data.resultObject.type}`
        )
      }
    >
      카카오톡 공유하기
    </div>
  );
}

export default KakaoButton;
