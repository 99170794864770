import React from 'react';
import { colors } from '../../constants/colors';

import KakaoChannel from './KakaoChannel';

function Footer() {
  const sendEmail = () => {
    window.location.href = `mailto:eunjitech@gmail.com`;
  };

  return (
    <div style={styles.footerWrap}>
      <p style={styles.footerCopyText}>
        후원문의
        <br />
        <br />
        <span
          onClick={sendEmail}
          style={{ ...styles.footerCopyText, cursor: 'pointer' }}
        >
          eunjitech@gmail.com
        </span>
        <br />
        <br />
      </p>
      <p style={styles.footerCopyText}>
        This site is for entertainment purposes only.
      </p>
      <p style={styles.footerCopyText}>
        Copyright 2023.Lee eunji.All rights reserved.
      </p>
      {/* TODO: 개인정보처리방침 */}
      <KakaoChannel />
    </div>
  );
}

const styles = {
  footerWrap: {
    marginTop: '4em',
    paddingBottom: '4em',
  },

  footerCopyText: {
    textAlign: 'center',
    color: colors.gray_500,
  },
};

export default Footer;
