import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { datas } from '../../data/data';
import banner from '../../assets/img/banner.svg';
import Spinner from '../Spinner';
import LoadingBar from './LoadingBar';

function Content() {
  // const dataContext = useContext(UseContext);
  // console.log(datas);
  const [imageOnLoad, setImageOnLoad] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };
  const imageLoad = () => {
    setImageOnLoad(true);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  return (
    <div>
      <img src={banner} alt="스마일배너" style={styles.bannerImage} />

      {/* 각 테스트 카드 */}
      <div>
        {datas.map((item, idx) => {
          return (
            <Link
              style={styles.testWrap}
              to={item.info.mainUrl}
              key={item.info.mainUrl.replaceAll('/', '')}
            >
              {!imageOnLoad && <LoadingBar text="로딩 중입니다..." />}
              <div className="mainImg" style={styles.mainImg}>
                <img
                  style={styles.testImg}
                  loading="lazy"
                  src={item.info.mainImage}
                  alt={item.info.mainUrl}
                  width={'100%'}
                  onLoad={imageLoad}
                />
              </div>
              <div style={styles.titleWrap}>
                <h2 style={styles.mainTitle}>{item.info.mainTitle}</h2>
                <h3 style={styles.subTitle}>{item.info.subTitle}</h3>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

const styles = {
  bannerImage: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '100%',
    height: 'auto',
  },
  testWrap: {
    margin: '30px auto 30px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    textDecoration: 'none',
  },
  mainImg: {
    border: '3px solid #000',
    borderRadius: '10px',
    maxheight: '300px',
    maxWidth: '500px',
    backgroundColor: '#fff',
    margin: 'auto 10px',
  },
  testImg: {
    display: 'block',
    height: '100%',
    width: '100%',
    borderRadius: '10px',
  },
  titleWrap: {
    width: '100%',
    paddingLeft: '10px',
  },
  mainTitle: { margin: '10px auto', fontSize: '1.5em', fontWeight: 'bold' },
  subTitle: { margin: '10px auto' },
};

export default Content;
