import React, { useState, useEffect } from 'react';
import copy from 'clipboard-copy';
import { Link, useNavigate } from 'react-router-dom';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { datas } from '../../data/data';
import { shareKakao } from '../../utils/util';
import contentHeader from '../../assets/img/contentHeader.svg';
import Spinner from '../Spinner';
import LoadingBar from '../layout/LoadingBar';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#F55E33' : '#F55E33',
  },
}));

//1a90ff
export const ContentHeader = () => {
  return (
    <Link to="/">
      <img
        src={contentHeader}
        alt="contentHeader"
        width="100%"
        style={{ borderBottom: '1px solid #eee' }}
      />
    </Link>
  );
};

function Quiz(props) {
  const navigate = useNavigate();
  const { test } = props;
  const currentTest = datas.find((item) => item.info.mainUrl === test);
  const answerTypeScore = {};
  for (let k = 0; k < currentTest.questions.length; k++) {
    for (let l = 0; l < currentTest.questions[k].answers.length; l++) {
      answerTypeScore[currentTest.questions[k].answers[l].type] = 0;
    }
  }
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [testInfo, setTestInfo] = useState({
    mode: 'intro', //intro: 시작하기전 메인, quiz: 진행 중, result: 테스트 끝남
    currentTest,
    answerTypeScore, //type별 스코어
    questionCount: 0,
    loading: false,
  });

  const determinePersonalityType = (data) => {
    // 각 요소의 점수를 비교하여 가장 높은 값을 선택
    const E = data.E > data.I ? 'E' : 'I';
    const S = data.S > data.N ? 'S' : 'N';
    const F = data.F > data.T ? 'F' : 'T';
    const J = data.J > data.P ? 'J' : 'P';

    // 선택된 값을 조합하여 성격 유형 문자열 생성
    const personalityType = `${E}${S}${F}${J}`;

    return personalityType;
  };

  const clickAnswer = (answer) => {
    if (testInfo.questionCount >= currentTest.questions.length - 1) {
      //todo: 로딩바 노출 후 결과페이지 나옴
      const resultType = determinePersonalityType(testInfo.answerTypeScore);

      navigate(`/${currentTest.info.mainUrl}/result/${resultType}`, {
        state: {
          mainTitle: currentTest.info.mainTitle,
          data: currentTest.results.filter(
            (result) => result.type === resultType,
          ),
        },
      });
    } else {
      let newScore = (testInfo.answerTypeScore[answer.type] || 0) + 1;
      setTestInfo({
        ...testInfo,
        questionCount: testInfo.questionCount + 1,
        answerTypeScore: {
          ...testInfo.answerTypeScore,
          [answer.type]: newScore,
        },
      });
    }
  };

  const clickLink = () => {
    const currentLocation = window.location.href;
    if (currentLocation) {
      copy(currentLocation);
      alert('링크가 복사되었습니다!');
    }
  };

  const handleShareKakao = () => {
    if (currentTest && currentTest.info) {
      console.log('Sharing to Kakao:', currentTest);
      shareKakao(
        currentTest.info.mainTitle,
        currentTest.info.subTitle,
        currentTest.info.mainImage,
        currentTest.info.mainUrl,
      );
    }
  };

  const IntroRender = () => {
    return (
      <>
        {!isImageLoaded && <LoadingBar text="로딩 중입니다.." />}
        <Link
          style={styles.introBox}
          key={currentTest?.info?.mainUrl.replaceAll('/', '')}
          onClick={() => {
            if (testInfo.questionCount !== currentTest.questions.length) {
              setTestInfo({ ...testInfo, mode: 'quiz' });
            } else {
              setTestInfo({ ...testInfo, mode: 'result' });
            }
          }}
        >
          <div style={styles.introImgBox}>
            <img
              loading="lazy"
              src={currentTest?.info?.fullImage}
              alt={currentTest?.info?.mainUrl}
              width={'100%'}
              style={styles.introImg}
              onLoad={() => setIsImageLoaded(true)}
            />
          </div>
        </Link>
        <div
          style={{
            whiteSpace: 'pre-line',
            fontSize: '1.2em',
            lineHeight: '1.5',
            paddingLeft: '10px',
            margin: '10px auto',
          }}
        >
          {currentTest?.info?.description}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <span style={{ margin: "20px auto" }}>친구에게 공유하기</span> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              gap: '10px',
            }}
          >
            <div
              onClick={handleShareKakao}
              style={{
                width: '100%',
                height: '50px',
                cursor: 'pointer',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center', // 세로 가운데 정렬을 위해 추가
                justifyContent: 'center',
                backgroundColor: '#F2DD03',
                fontSize: '1.2em',
              }}
            >
              카카오톡 공유하기
            </div>
          </div>
          <div
            style={{ cursor: 'pointer', fontSize: '1.2em', padding: '20px' }}
            onClick={clickLink}
          >
            링크 복사하기
          </div>
        </div>
      </>
    );
  };
  const QuizRender = () => {
    return (
      <>
        <div style={styles.quizWrap}>
          {/* progress bar */}
          <BorderLinearProgress
            variant="determinate"
            value={
              ((testInfo.questionCount + 1) * 100) /
              currentTest.questions.length
            }
          />
          {/* question */}
          <div style={styles.questionWrap}>
            <span style={styles.questionText}>
              {currentTest.questions[testInfo.questionCount].question}
            </span>
          </div>
          {/* answer */}
          {currentTest.info.scoreType === 'typeMBTI' && (
            <div style={styles.answersWrap}>
              {currentTest.questions[testInfo.questionCount].answers.map(
                (answer) => (
                  <div
                    style={styles.answerBox}
                    onClick={() => clickAnswer(answer)}
                  >
                    <span style={styles.answerText}>{answer.content}</span>
                  </div>
                ),
              )}
            </div>
          )}
          {/* count 숫자 */}
          <div style={styles.questionCountWrap}>
            <span style={styles.questionCountText}>
              {testInfo.questionCount + 1}/{currentTest.questions.length}
            </span>
          </div>
        </div>
      </>
    );
  };

  //mode 별 렌더링할 페이지
  const ModePage = () => {
    return (
      (testInfo.mode === 'intro' && <IntroRender />) ||
      (testInfo.mode === 'quiz' && <QuizRender />)
    );
  };

  return (
    <>
      <ContentHeader />
      <ModePage style={styles.wrap} />
    </>
  );
}

const styles = {
  wrap: {
    maxWidth: '500px',
    width: '100%',
  },
  introBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: '#fff',
    testDecoration: 'none',
    marginTop: '-3px',
  },
  quizWrap: { margin: '30px' },
  questionWrap: { margin: '20px auto' },
  questionText: {
    whiteSpace: 'pre-line',
    fontSize: '1.2em',
    fontWeight: 'bold',
    lineHeight: '110%',
    fontFamily: 'Ycomputer, sans-serif',
  },
  answersWrap: {},
  questionCountWrap: { textAlign: 'center', margin: '10px auto' },
  questionCountText: { fontSize: '1em' },
  answerBox: {
    padding: '45px 30px',
    margin: '20px auto',
    backgroundColor: '#fdded6',
    border: '3px solid ##fff',
    borderRadius: '10px',
    boxShadow:
      '2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, -2px 2px 4px 0px rgba(255, 255, 255, 0.25) inset',
    textAlign: 'center',
    cursor: 'pointer',
  },
  answerText: {
    lineHeight: '120%',
    fontSize: '1.1em',
    fontWeight: 500,
    whiteSpace: 'pre-line',
  },
  introImgBox: {},
  introImg: { display: 'block' },
};

export default Quiz;
