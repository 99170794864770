import React, { createContext } from "react";
import { datas } from "../data/data";

const initValue = {
  language: "KO",
  setLanguage: () => {},
};

export const UseContext = createContext(initValue);

export function ContextProvider(props) {
  const [language, setLanguage] = React.useState("KO");
  const contextValue = {
    language,
    setLanguage,
    datas,
  };

  return (
    <UseContext.Provider value={contextValue}>
      {props.children}
    </UseContext.Provider>
  );
}
