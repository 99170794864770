import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ContextProvider } from './provider/useContext';
import { Helmet } from 'react-helmet';

import Main from './components/pages/Main';
import ScrollToTop from './components/layout/ScrollToTop';
import NotFound from './components/pages/NotFound';
import './styles/App.css';
import EmailButton from './components/layout/EmailButton';

import { datas } from './data/data';
import Result from './components/pages/Result';
import Quiz from './components/pages/Quiz';

import { analytics, storage } from './api/firebase';
import Footer from './components/layout/Footer';

// 테스트배너이미지 500*300
// 테스트풀이미지 500*800

const MainMetaTag = () => {
  return (
    <Helmet>
      <title>성향 심리 테스트 - 잼잼테스트 JamJamTest</title>
      <meta
        name="description"
        content="당신의 심리를 알아보는 테스트 사이트입니다."
      />
      <meta name="keywords" content="심리테스트, 성격테스트, 심리분석" />
      <meta name="author" content="Lee eunji" />
      <meta
        property="og:title"
        content="성향 심리 테스트 - 잼잼테스트 JamJamTest"
      />
      <meta
        property="og:description"
        content="내 성향을 분석해보는 재미있는 심리테스트 : 색깔심리, 고양이성격테스트"
      />
      <meta property="og:url" content="https://www.jamjamtest.co.kr/" />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.jamjamtest.co.kr/" />
      <meta
        property="og:title"
        content="성향 심리 테스트 - 잼잼테스트 JamJamTest"
      />
      <meta
        property="og:description"
        content="내 성향을 분석해보는 재미있는 심리테스트 : 색깔심리, 고양이성격테스트"
      />
      <meta
        property="og:image"
        content="https://firebasestorage.googleapis.com/v0/b/allyourneeds-f38a8.appspot.com/o/banner.svg?alt=media&token=c009ee8f-73ff-4963-aaa2-5b74015538c0"
      />
      <meta
        property="og:image:alt"
        content="내 성향을 분석해보는 재미있는 심리테스트 : 색깔심리, 고양이성격테스트"
      />
    </Helmet>
  );
};

function App() {
  const [testUrl, setTestUrl] = useState([]);
  const [testResultUrl, setTestResultUrl] = useState([]);
  const [testResultQueryUrl, setTestResultQueryUrl] = useState([]);
  const [testUrlImg, setTestUrlImg] = useState([]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    document.head.appendChild(script);

    const tempTestResultQueryUrl = [];
    datas.map((item, idx) => {
      setTestUrl([...testUrl, `/${item.info.mainUrl}`]);
      setTestResultUrl([...testResultUrl, `/${item.info.mainUrl}/result`]);
    });
    datas.forEach((item) => {
      item.results.forEach((result) => {
        tempTestResultQueryUrl.push({
          query: result.query,
          mainUrl: item.info.mainUrl,
        });
      });
    });
    setTestResultQueryUrl(tempTestResultQueryUrl);
    const tempTestUrlImg = [];
    datas.forEach((item) => {
      tempTestUrlImg.push({
        mainUrl: item.info.mainUrl,
        mainImage: item.info.mainImage,
        mainTitle: item.info.mainTitle,
        subTitle: item.info.subTitle,
      });
    });
    setTestUrlImg(tempTestUrlImg);
  }, []);

  return (
    <div className="App">
      {/* <div className="bg-image"></div> */}
      <BrowserRouter>
        <ContextProvider>
          <ScrollToTop>
            <Routes>
              {/* Main */}
              <Route
                path="/"
                element={
                  <>
                    <MainMetaTag />
                    <Main />
                  </>
                }
              />
              {/* go to "Intro" page */}
              {testUrl.map((item) => (
                <Route
                  path={item}
                  element={<Quiz test={item.replaceAll('/', '')} />}
                  key={item.replaceAll('/', '')}
                  exact
                />
              ))}
              {/* go to "Each Result contents" page */}
              {testResultQueryUrl.map((item) => (
                <Route
                  path={'/:mainUrl/result/:type'}
                  element={<Result />}
                  key={item.mainUrl + '_' + item.query}
                  exact
                />
              ))}

              <Route path="*" element={<Main />}></Route>
            </Routes>
            <Footer />
          </ScrollToTop>
        </ContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
