import React from "react";
import { Link } from "react-router-dom";

function AgainButton({ url }) {
  return (
    <Link
      to={`/${url}`}
      style={{
        width: "100%",
        height: "50px",
        cursor: "pointer",
        borderRadius: "10px",
        backgroundColor: "#F2F2F2",
        textDecoration: "none",
        display: "flex",
        alignItems: "center", // 세로 가운데 정렬을 위해 추가
        justifyContent: "center",
      }}
    >
      <div>다시하기</div>
    </Link>
  );
}

export default AgainButton;
