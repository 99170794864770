import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import copy from 'clipboard-copy';

import LoadingBar from '../layout/LoadingBar';
import { datas } from '../../data/data';
import AgainButton from '../layout/AgainButton';
import KakaoButton from '../layout/KakaoButton';
import { ContentHeader } from './Quiz';

function Result() {
  const { mainUrl, type } = useParams(); // 경로에서 파라미터(type) 가져오기

  const [imageLoad, setImageLoag] = useState(true);

  const clickLink = () => {
    const currentLocation = window.location.href;
    if (currentLocation) {
      copy(currentLocation);
      alert('링크가 복사되었습니다!');
    }
  };

  function getResultByType(mainUrl, targetType) {
    // mainUrl이 "catBTI"이고, 해당하는 데이터 찾기
    const catBtiData = datas.find((data) => data.info.mainUrl === mainUrl);

    // catBtiData가 존재하고, results 배열에서 targetType에 해당하는 객체 찾기
    if (catBtiData && catBtiData.results) {
      const resultObject = catBtiData.results.find(
        (result) => result.type === targetType,
      );

      return { catBtiData: catBtiData, resultObject: resultObject };
    }

    // 찾지 못한 경우
    return null;
  }

  const data = getResultByType(mainUrl, type);

  const onImageLoad = () => {
    const timeoutId = setTimeout(() => {
      setImageLoag(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  };

  return (
    <>
      {imageLoad && <LoadingBar />}
      <ContentHeader />
      <img
        loading="lazy"
        src={data.resultObject.img_src}
        alt={data.resultObject.type}
        width={'100%'}
        onLoad={onImageLoad}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '10px auto',
        }}
      >
        {/* <span style={{ fontSize: "1.1em" }}>결과 공유하기</span> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            gap: '10px',
          }}
        >
          <KakaoButton data={data} />
          <AgainButton url={mainUrl} />
        </div>
        <div
          style={{ cursor: 'pointer', fontSize: '1.2em', padding: '20px' }}
          onClick={clickLink}
        >
          링크 복사하기
        </div>
      </div>
    </>
  );
}

const styles = {};

export default Result;
